import { Component, For, createMemo } from "solid-js";
import { useSearchParams } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { xMark } from "solid-heroicons/outline";

type ActiveFiltersProps = {
  filters: Record<string, string>;
  exclude?: string[] | string;
};

export const productFilters: Record<string, string> = {
  category: "Category",
  collection: "Collection",
  colour: "Colour",
  profile: "Profile",
  finish: "Finish",
  minWidth: "Min-Width",
  maxWidth: "Max-Width",
  minHeight: "Min-Height",
  maxHeight: "Max-Height",
  minRabbet: "Min-Rabbet",
  maxRabbet: "Max-Rabbet",
  price: "Price",
};

export const ActiveFilters: Component<ActiveFiltersProps> = (props) => {
  const [_, setParams] = useSearchParams();

  const exclusions = () => {
    if (!props.exclude) return [""];
    if (typeof props.exclude === "string") return [props.exclude];
    return props.exclude;
  };

  const activeFilters = createMemo(() => {
    if (!props.filters) return [];
    return Object.keys(props.filters)
      .filter((filter) => !exclusions().includes(filter))
      .map((filter) => {
        if (filter === "category") {
          return { label: props.filters[filter], value: filter };
        } else {
          return { label: productFilters[filter], value: filter };
        }
      });
  });

  const clearAllActive = () => {
    const undefinedObj = activeFilters().reduce((memo, filter) => {
      memo[filter.value] = undefined;
      return memo;
    }, {} as Record<string, undefined>);
    setParams(undefinedObj);
  };

  return (
    <div class="flex gap-1 flex-wrap items-center text-xs">
      <button
        onClick={clearAllActive}
        class="px-2 rounded-full flex h-5 items-center justify-between text-roma-blue border border-roma-blue"
      >
        <span class="mb-px">Clear all filters</span>
      </button>
      <For each={activeFilters()}>
        {(filter) => (
          <button
            onClick={() => setParams({ [filter.value]: undefined })}
            class="bg-roma-blue text-white px-2 rounded-full flex h-5 items-center justify-between"
          >
            <span class="mb-px">{filter.label}</span>

            <Icon path={xMark} class="ml-1 w-3" stroke-width={2} />
          </button>
        )}
      </For>
    </div>
  );
};

import {
  Component,
  Show,
  For,
  Accessor,
  Setter,
  Switch,
  Match,
  createMemo,
  createEffect,
} from "solid-js";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { ProgressBar } from "~/components/inputs";
import { Icon } from "solid-heroicons";
import { checkCircle, xCircle, xMark } from "solid-heroicons/outline";
import { imageUrl } from "~/utils/products";
import { PT } from "~/utils/products";
import Button from "~/components/Button";
import { BaseLoader } from "~/components/utility";
import { InfoBox } from "~/components/utility";
import { ProductImage } from "~/components/product";

import { createChunkedRequest } from "~/components/ordering/utils/createChunkedRequest";

// ! ADDCART MODAL
type AddCartModalProps = {
  selectedSamples: Accessor<Set<string>>;
  onClose: () => void;
  afterAdd?: () => void;
  addToCartStatus: Accessor<any>;
  setAddToCartStatus: Setter<any>;
  updateSelectedSamplesBySku: (skus: string[], mode: "add" | "delete") => void;
};

export const AddCartModal: Component<AddCartModalProps> = (props) => {
  const { session } = useSessionContext();
  const { setPanel } = useSiteContext();
  const listOfSkus = () => Array.from(props.selectedSamples());



  const payload = createMemo(() => {
    if (!listOfSkus()) return [];
    return [...listOfSkus()].map((sku) => ({
      Type: PT.CORNERSAMPLE,
      Quantity: 1,
      SubItems: [
        {
          SKU: `${sku}-COR`,
          Moulding: sku,
        },
      ],
    }));
  });

  const {
    completionState,
    request,
    skus,
    actionIsPending,
    pendingPosition,
  } = createChunkedRequest(
    payload,
    `${import.meta.env.VITE_ROMA_API}/cart/${session()?.cart_id}/lines`,
    10
  );

  const uncheckSuccessful = () => {
    if (!props.addToCartStatus()?.result) return;
    const successfullSkus = [...(props.addToCartStatus()?.result ?? [])].reduce(
      (memo, bool, index) => {
        if (bool) {
          memo.push(payload()[index].SubItems[0].Moulding);
        }
        return memo;
      },
      []
    );

    if (successfullSkus) {
      props.updateSelectedSamplesBySku(successfullSkus, "delete");
    }
  };

  const addBulkToCart = async () => {
    const resp = await request();
    props.setAddToCartStatus(resp);
    uncheckSuccessful();
    props.afterAdd?.();
  };


  const scrollToIndex = (index: string) => {
    const container = document.getElementById("scrollContainer");
    const target = document.querySelector(`[data-item-index="${index}"]`);
    if (container && target) {
      container.scrollTop = (target as HTMLElement).offsetTop;
    }
  };

  createEffect(() => {
    if (!pendingPosition()) return;
    setTimeout(
      () => scrollToIndex(pendingPosition()?.toString() as string),
      300
    );
  });

  return (
    <div class="flex flex-col w-full gap-2">
      <h4 class="text-2xl">Add Corner Samples</h4>
      <Show
        when={listOfSkus().length !== 0}
        fallback={<p>No corner samples selected</p>}
      >
        <p>Add the following corner samples to your cart?</p>
        <p class="font-light">
          ({listOfSkus().length} Item{listOfSkus().length > 1 && "s"})
        </p>
        <ul
          id="scrollContainer"
          class="grid grid-cols-2 my-5 max-h-52 overflow-y-auto gap-2 scroll-smooth relative"
        >
          <For each={listOfSkus()}>
            {(item, index) => (
              <li
                class="flex gap-2 items-center border"
                data-item-index={index()}
              >
                <div class="w-8 h-8 shrink-0 grow-0 flex bg-roma-grey p-1 justify-center items-center">
                  <ProductImage
                    src={imageUrl(item, "moulding", 1)}
                    alt=""
                    height={24}
                    width={24}
                    quality={95}
                  />
                </div>
                <p class="font-bold sm:font-light text-sm">{item}</p>
                <div class="ml-auto mr-1">
                  <Switch>
                    <Match when={skus[index()] === true}>
                      <Icon
                        path={checkCircle}
                        class="w-5 h-5 text-success-green"
                      />
                    </Match>
                    <Match when={skus[index()] === false}>
                      <Icon path={xCircle} class="w-5 h-5 text-red-500" />
                    </Match>
                    <Match when={skus[index()] === null && actionIsPending()}>
                      <div class="mr-0.5">
                        <BaseLoader width={4} height={4} class="!mr-0" />
                      </div>
                    </Match>
                  </Switch>
                </div>
                <Show when={!actionIsPending() && !props.addToCartStatus()}>
                  <button
                    class="border mr-1 hover:bg-roma-grey"
                    onClick={() => {
                      props.updateSelectedSamplesBySku(
                        [item as string],
                        "delete"
                      );
                    }}
                  >
                    <Icon path={xMark} class="w-5 p-1" />
                  </button>
                </Show>
              </li>
            )}
          </For>
        </ul>
      </Show>

      <Switch>
        <Match when={props.addToCartStatus()?.status === "PARTIAL"}>
          <InfoBox type="warning" class="mb-3 text-xs">
            Something went wrong while attempting to add some items to your
            cart. Please try again shortly, or call Customer Care for support.
            <br />
            Errors with the following skus:
            <div class="mt-1 bg-roma-grey w-full border p-3 select-all">
              {props
                .addToCartStatus()
                ?.errors.map((i: number) => payload()[i].SubItems[0].Moulding)
                .join(", ")}
            </div>
          </InfoBox>
        </Match>
        <Match when={props.addToCartStatus()?.status === "SUCCESS"}>
          <div class="flex flex-col items-center gap-4 py-10">
            <Icon path={checkCircle} class="text-success-green w-10 h-10" />
            <p class="text-lg">Samples were successfully added!</p>
          </div>
        </Match>
        <Match when={props.addToCartStatus()?.status === "FAILURE"}>
          <InfoBox type="error" class="mb-3">
            Something went wrong while attempting to add these items to your
            cart. Please try again shortly, or call Customer Care for support.
          </InfoBox>
        </Match>
      </Switch>

      <Show when={listOfSkus().length !== 0}>
        <Show when={actionIsPending()}>
          <div class="bg-roma-grey p-3 text-xs text-center rounded-md">
            Do not close window while adding to cart
          </div>
        </Show>
        <Show when={actionIsPending() || completionState() === 1}>
          <ProgressBar
            val={completionState() ? completionState()! * 100 : 0}
            label="Progress..."
          />
        </Show>
      </Show>

      <div class="flex flex-col gap-2 mt-4">
        <Show
          when={["SUCCESS", "PARTIAL"].includes(
            props.addToCartStatus()?.status
          )}
        >
          <Button
            style="outline"
            class="w-full text-sm"
            onClick={()=>{
              props.onClose();
              setPanel({mode: "cart", open: true})
            }}
            disabled={actionIsPending()}
          >
            View Cart
          </Button>
        </Show>
        <Show when={!actionIsPending()}>
          <Show
            when={
              !["SUCCESS", "PARTIAL"].includes(props.addToCartStatus()?.status)
            }
          >
            <Button
              class="w-full text-sm"
              disabled={listOfSkus().length === 0 || actionIsPending()}
              onClick={addBulkToCart}
            >
              Add to Cart
            </Button>
          </Show>
          <Button
            class="w-full text-sm"
            style="outline"
            onClick={() => {
              props.onClose();
            }}
            disabled={actionIsPending()}
          >
            Continue Shopping
          </Button>
        </Show>
      </div>
    </div>
  );
};
import {
  Component,
  createSignal,
  Show,
  For,
  Accessor,
  Setter,
  Switch,
  Match,
  createMemo,
} from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { useSessionContext } from "~/utils/contexts";
import { useSearchParams, A, createAsync } from "@solidjs/router";
import { SelectBox, TextFieldInput } from "~/components/inputs";
import { Icon } from "solid-heroicons";
import {
  adjustmentsHorizontal,
  heart,
  squares_2x2 as squares_2x2outline,
  squaresPlus as squaresPlusOutline,
  exclamationCircle,
  magnifyingGlass,
} from "solid-heroicons/outline";
import {
  heart as heartSolid,
  squares_2x2,
  squaresPlus,
} from "solid-heroicons/solid";
import { imageUrl } from "~/utils/products";
import { Product } from "~/services/roma-api/products/types";
import { PT } from "~/utils/products";
import { Modal } from "~/components/utility";
import { type ShopSearchParams } from "~/utils/types";
import { AddCartModal, CornerSampleProduct, ActiveFilters } from "./";
import { retrieveCart } from "~/services/cart";
import { usePrompt } from "~/components/utility";

type CornerSampleShopProps = {
  list: Accessor<Product[]>;
  filterPanel: [Accessor<boolean>, Setter<boolean>];
  sortByOptions: Accessor<{ label: string; value: string }[]>;
};

// TODO - favourites

const CornerSampleShop: Component<CornerSampleShopProps> = (props) => {
  const { isPartner, permission } = useSessionContext();
  const [params, setParams] = useSearchParams<ShopSearchParams>();
  const [Prompt, openPrompt] = usePrompt();
  const [showModal, setShowModal] = createSignal(false);
  const [showFilters, setShowFilters] = props.filterPanel;
  const [searchVal, setSearchVal] = createSignal<string>();
  const [limitSamples, setLimitSamples] = createSignal(true);
  const [gridSmall, setGridSmall] = createSignal(false);
  const [addToCartStatus, setAddToCartStatus] = createSignal<any>();
  const [selectedSamples, setSelectedSamples] = createSignal<Set<string>>(
    new Set()
  );
  const SAMPLE_LIMIT = 48;

  const search = debounce((val: string) => {
    setSearchVal(val);
  }, 500);

  // TODO - refactor into createAsync / retrieveCart etc..

  const samplesInCart = createAsync(async () => {
    try {
      const data = await retrieveCart("current");
      if (!data.Lines) return [];

      // Filter for corner samples and map to SKUs
      const cornerSamples = data.Lines?.filter(
        (line: any) => line.Type === PT.CORNERSAMPLE
      ).map((cs: any) => cs.SubItems[0].Moulding);

      return cornerSamples as string[];
    } catch (error) {
      console.log("Error retrieving cart information");
      return null;
    }
  });

  // Selection functions
  const updateSelectedSamplesBySku = (
    skus: string[],
    mode: "add" | "delete"
  ): void => {
    setSelectedSamples((prev) => {
      const newSelectedSamples = new Set(prev);
      if (mode === "add") {
        skus.forEach((sku) => newSelectedSamples.add(sku));
      } else {
        skus.forEach((sku) => newSelectedSamples.delete(sku));
      }
      return newSelectedSamples;
    });
  };

  const toggleSelected = (sku: string, checked: boolean) => {
    updateSelectedSamplesBySku([sku], checked ? "add" : "delete");
  };

  const visibleSkusNotInCart = () => {
    // previously was using the data-attributes on the rendered CornerSampleProducts,
    // make sure this works as intended.
    const list = (
      limitSamples() ? samples().list.slice(0, SAMPLE_LIMIT) : samples().list
    ).reduce((skus, product) => {
      if (!product.ExistsInCart) {
        skus.push(product.SKU);
      }
      return skus;
    }, [] as string[]);
    return list;
  };

  const selectAllVisible = () => {
    const skus = visibleSkusNotInCart();
    updateSelectedSamplesBySku(skus, "add");
  };

  const deselectAllVisible = () => {
    const skus = visibleSkusNotInCart();
    updateSelectedSamplesBySku(skus, "delete");
  };

  const deselectAll = async () => {
    const numOfItems = selectedSamples().size;
    if (numOfItems > 0) {
      const ok = await openPrompt({
        title: "Deselect All",
        description: `Are you sure you would like to deselect all? This will clear all ${numOfItems} item(s).`,
        requireInteraction: true,
        options: [
          { label: "Cancel", value: false, priority: true },
          { label: "Deselect All", value: true, style: "red" },
        ],
      });
      if (ok) {
        setSelectedSamples(new Set() as Set<string>);
      }
    }
  };

  const samples = createMemo(() => {
    const obj = {
      matches: 0,
      list: props.list() as unknown as (Product & { ExistsInCart: boolean })[],
      total() {
        return this.list.length;
      },
      totalSelectable() {
        return this.list.length - this.matches;
      },
      totalRendered() {
        return limitSamples()
          ? Math.min(SAMPLE_LIMIT, this.totalSelectable())
          : this.totalSelectable();
      },
    };

    const cartSkus = samplesInCart();

    if (searchVal() && searchVal() !== "") {
      if (searchVal()!.includes(",")) {
        const vals = searchVal()
          ?.split(",")
          .map((val) => val.trim());

        obj.list = obj.list.filter((prod) => {
          return (vals as string[]).some(
            (val) =>
              prod.SKU.includes(val) ||
              prod.Collection.toLowerCase().includes(val.toLowerCase()) ||
              prod.Profile.toLowerCase().includes(val.toLowerCase())
          );
        });
      } else {
        const val = searchVal() as string;
        obj.list = obj.list.filter(
          (prod) =>
            prod.SKU.includes(val) ||
            prod.Collection.toLowerCase().includes(val.toLowerCase()) ||
            prod.Profile.toLowerCase().includes(val.toLowerCase())
        );
      }
    }

    // // !TESTING

    // if (cartSkus && cartSkus?.length > 0) {
    //   const cartSet = new Set(cartSkus);
    //   // Only create new objects for the ones we need to change
    //   for (const prod of obj.list) {
    //     if (cartSet.has(prod.SKU)) {
    //       // Only create new object if we need to change it
    //       Object.assign(prod, { ...prod, ExistsInCart: true });
    //       cartSet.delete(prod.SKU);
    //       obj.matches++;
    //     } else {
    //       // Ensure ExistsInCart is set to false if not already
    //       if (prod.ExistsInCart !== false) {
    //         Object.assign(prod, { ...prod, ExistsInCart: false });
    //       }
    //     }
    //     // Early break if we've found all cart items
    //     if (cartSet.size === 0) break;
    //   }
    // } else {
    //   // Only reset products that were previously in cart
    //   for (const prod of obj.list) {
    //     if (prod.ExistsInCart !== false) {
    //       Object.assign(prod, { ...prod, ExistsInCart: false });
    //     }
    //   }
    // }

    // ! WORKING
    if (cartSkus && cartSkus?.length > 0) {
      const cartSet = new Set(cartSkus);

      obj.list = obj.list.map((prod) => {
        const isInCart = cartSet.has(prod.SKU);
        if (isInCart) {
          obj.matches++;
        }
        return { ...prod, ExistsInCart: isInCart };
      });
    } else {
      obj.list = obj.list.map((prod) => ({ ...prod, ExistsInCart: false }));
    }

    return obj;
  });

  const excludedFilters = ["samples", "sort"];

  const filtersActive = createMemo(() => {
    if (!params) return false;
    if (
      Object.keys(params).filter((param) => !excludedFilters.includes(param))
        .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      <Show
        when={isPartner() && permission.PLACEORDER}
        fallback={
          <>
            <Switch>
              <Match when={!isPartner()}>
                <div class=" py-10 px-2 grow">
                  <div class="bg-roma-grey rounded-md  min-h-[50vh] mx-auto shadow-sm flex items-center justify-center px-4">
                    <p class="max-w-md text-center">
                      Please{" "}
                      <button
                        class="text-roma-blue"
                        onClick={() => {
                          setParams({ signIn: true });
                        }}
                      >
                        sign in
                      </button>{" "}
                      to make a corner sample order.
                    </p>
                  </div>
                </div>
              </Match>
              <Match when={isPartner() && !permission.PLACEORDER}>
                <div class=" py-10 px-2 grow">
                  <div class="bg-roma-grey rounded-md  min-h-[50vh] mx-auto shadow-sm flex items-center justify-center px-4">
                    <p class="max-w-md text-center">
                      Your Roma Partner account is not set up to place orders.
                      Please{" "}
                      <A href="/support" class="text-roma-blue">
                        contact support
                      </A>{" "}
                      for more details.
                    </p>
                  </div>
                </div>
              </Match>
            </Switch>
          </>
        }
      >
        <div
          class="w-full grow shrink-0 md:shrink col-span-8 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-x-3 gap-y-6"
          classList={{
            "lg:grid-cols-12": !gridSmall(),
            "lg:grid-cols-9": !!gridSmall(),
          }}
        >
          {/* DEBUGGING */}
          {/* <div class="border border-orange-500 p-2 text-orange-500 w-full col-span-full">
          {Array.from(selectedSamples()).join(",")}
          <p>{selectedSamples().size}</p>
        </div> */}
          <Show when={samplesInCart() === null}>
            <div class="!-mb-6 flex items-center text-xs col-span-full">
              <Icon
                path={exclamationCircle}
                class="w-5 h-5 text-orange-500 mr-1 shrink-0"
              />
              <span class="text-roma-dark-grey">
                Error retrieving existing cart items, caution when adding
                samples as duplicate entries may occur{" "}
              </span>
            </div>
          </Show>

          <div class="col-span-full bg-white bg-opacity-80 backdrop-blur-lg py-3 sticky top-0 z-10 grid sm:grid-cols-[auto,_min-content] gap-2">
            {/* Search Bar */}
            <div class="sm:mr-2">
              <TextFieldInput
                class="!rounded-sm max-sm:placeholder:text-xs max-sm:py-1"
                rootClass="grow"
                placeholder="Search by SKU, Collection, or Profile"
                icon={magnifyingGlass}
                defaultValue={searchVal()}
                onChange={search}
              />
            </div>

            {/* Control Buttons */}
            <div class="flex max-sm:justify-between max-sm:grow max-sm:flex-wrap items-center gap-2 sm:gap-3">
              <button
                aria-label="Show filters"
                class="flex items-center gap-2 border border-gray-300 rounded-full max-sm:grow max-sm:px-3 p-1 sm:p-2 max-sm:text-xs"
                onClick={() => setShowFilters(!showFilters())}
              >
                <Icon path={adjustmentsHorizontal} class="w-5" />
                <span class="sm:hidden">{samples().list.length} Items</span>
              </button>

              <SelectBox
                class="max-sm:grow whitespace-nowrap"
                triggerClass="!rounded-full max-sm:py-1"
                valueClass="max-sm:text-xs"
                options={props.sortByOptions()}
                defaultValue={params.sort !== "" ? params.sort : "alpha"}
                onChange={(option) => {
                  setParams({ sort: option.value }, { replace: true });
                }}
                inlineTitle="Sort by:"
              />

              <div class="hidden lg:flex items-center gap-3">
                {/* TODO FAVOURITES */}
                {/* <Icon
                  path={params.showFavourites == "true" ? heartSolid : heart}
                  class="w-6 aspect-square cursor-pointer text-roma-blue"
                  onClick={() => {
                    if (
                      params.showFavourites == "false" ||
                      !params.showFavourites
                    ) {
                      setParams({
                        showFavourites: true,
                      });
                    } else {
                      setParams({ showFavourites: undefined });
                    }
                  }}
                /> */}

                <Icon
                  path={gridSmall() ? squares_2x2outline : squares_2x2}
                  class="w-6 aspect-square cursor-pointer"
                  onClick={() => setGridSmall(false)}
                />
                <Icon
                  path={gridSmall() ? squaresPlus : squaresPlusOutline}
                  class="w-6 aspect-square cursor-pointer"
                  onClick={() => setGridSmall(true)}
                />
              </div>
            </div>

            {/* Selection Controls */}
            <div class="col-span-full w-full flex flex-col sm:flex-row justify-between gap-1 text-xs sm:text-sm">
              <div class="flex max-sm:flex-col gap-1 child:py-1 child:sm:py-2 child:px-4 child:border child:border-gray-300 child:text-roma-dark-grey">
                <button class="hover:bg-roma-grey" onClick={selectAllVisible}>
                  Select All Visible
                  {/* <span class="text-roma-medium-grey font-light ml-1">
                    ({samples().totalRendered()})
                  </span> */}
                </button>

                <button class="hover:bg-roma-grey" onClick={deselectAllVisible}>
                  Deselect All Visible
                  {/* <span class="text-roma-medium-grey font-light ml-1">
                    ({samples().totalRendered()})
                  </span> */}
                </button>
              </div>

              <div class="flex max-sm:flex-col gap-1 child:py-1 child:sm:py-2 child:px-4 child:border child:border-gray-300 child:text-roma-dark-grey">
                <button class="hover:bg-roma-grey" onClick={deselectAll}>
                  Deselect All
                  <span class="text-roma-medium-grey font-light ml-1">
                    ({selectedSamples().size})
                  </span>
                </button>
                <button
                  class="hover:bg-roma-blue hover:text-white bg-faint-blue"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Add Selected to Cart ({selectedSamples().size})
                </button>
              </div>
            </div>
          </div>

          {/* Active Filters */}
          <Show when={filtersActive()}>
            <div class="col-span-full py-1">
              <ActiveFilters filters={params} exclude={excludedFilters} />
            </div>
          </Show>

          {/* Results */}
          <Show
            fallback={
              <div class="bg-roma-grey col-span-full text-center p-20">
                No products were found.
              </div>
            }
            when={samples().list.length > 0}
          >
            <Show when={props.list().length > SAMPLE_LIMIT}>
              <div class="col-span-full flex flex-col justify-center items-center -mt-4 text-sm text-roma-dark-grey">
                <Show
                  when={samples().totalRendered() < samples().totalSelectable()}
                >
                  <span class=" mb-1">
                    Displaying {samples().totalRendered()} of{" "}
                    {samples().totalSelectable()} mouldings
                  </span>
                </Show>
                <Show when={limitSamples()}>
                  <button
                    class="border border-gray-300  py-1 px-4 w-full"
                    onClick={() => setLimitSamples(false)}
                  >
                    Load all products
                  </button>
                </Show>
              </div>
            </Show>

            {/* Products Grid */}

            <For
              each={
                limitSamples()
                  ? samples().list?.slice(0, SAMPLE_LIMIT)
                  : samples().list
              }
            >
              {(product) => (
                <div class="col-span-3">
                  <CornerSampleProduct
                    type={PT.MOULDING}
                    existsInCart={product.ExistsInCart}
                    loading="lazy"
                    sku={product.SKU}
                    is_new={product.IsNew}
                    is_discontinued={product.Discontinued}
                    is_discontinuing={product.Discontinuing}
                    image1={imageUrl(product.SKU, PT.MOULDING, 1)}
                    image2={imageUrl(product.SKU, PT.MOULDING, 5)}
                    hover={true}
                    colour={product.ColourDescription}
                    collection={product.Collection}
                    category={product.Category}
                    profile={product.Profile}
                    width={product.Width}
                    height={product.Height}
                    rabbet={product.Rabbet}
                    onCheck={(checked: boolean) => {
                      toggleSelected(product.SKU, checked);
                    }}
                    isChecked={selectedSamples().has(product.SKU)}
                  />
                </div>
              )}
            </For>

            {/* Load More */}
            <Show when={limitSamples() && samples().list.length > SAMPLE_LIMIT}>
              <div class="col-span-full flex justify-center mt-4">
                <button
                  class="border border-gray-300 py-1 px-4"
                  onClick={() => setLimitSamples(false)}
                >
                  Load all products
                </button>
              </div>
            </Show>
          </Show>
        </div>
        <Show when={showModal()}>
          <Modal
            open={() => showModal()}
            onClose={() => {
              setShowModal(false);
              setAddToCartStatus(undefined);
            }}
          >
            <AddCartModal
              selectedSamples={selectedSamples}
              addToCartStatus={addToCartStatus}
              setAddToCartStatus={setAddToCartStatus}
              updateSelectedSamplesBySku={updateSelectedSamplesBySku}
              onClose={() => {
                setShowModal(false);
                setAddToCartStatus(undefined);
              }}
              afterAdd={() => {
                // updateSamplesInCart();
              }}
            />
          </Modal>
        </Show>
      </Show>
      {Prompt}
    </>
  );
};

export default CornerSampleShop;

import {
  Component,
  Show,
  Switch,
  Match,
} from "solid-js";
import { A } from "@solidjs/router";
// import { ProductModel } from "~/components/Product";
import fractionString from "~/utils/fraction";
import { Icon } from "solid-heroicons";
import {
  arrowTopRightOnSquare,
  check,
  arrowDownTray,
} from "solid-heroicons/outline";
import { ProductImage, type ProductModel } from "~/components/product";

// TODO check out/change type ProductModel

export const CornerSampleProduct: Component<
  {
    class?: string;
    classList?: object;
    loading?: "eager" | "lazy" | undefined;
    existsInCart?: boolean;
    onCheck: (checked: boolean) => void;
    isChecked: boolean;
  } & ProductModel
> = (props) => {
  return (
    <div
      class="flex flex-col gap-y-2 col-span-2 group"
      classList={{ [`${props.class}`]: !!props.class, ...props.classList }}
    >
      <div class="w-full group aspect-square bg-roma-grey flex items-center justify-center relative">
        <div
          class="w-full aspect-square flex items-center justify-center p-8"
          classList={{
            "absolute inset-0 group-hover:opacity-0 transition-opacity duration-200":
              !!props.hover,
          }}
        >
          <ProductImage
            loading={props.loading || "lazy"}
            src={props.image1}
            width={440}
            height={440}
            quality={85}
            alt={`SKU #${props.sku}`}
            breakpoints={[300, 440]}
          />
        </div>
        <Show when={props.hover}>
          <ProductImage
            loading={props.loading}
            alt={`SKU #${props.sku}`}
            src={props.image2}
            width={440}
            height={440}
            quality={85}
            breakpoints={[300, 440]}
            class="w-full opacity-0 group-hover:opacity-100 transition duration-200"
          />
        </Show>
        <Show when={!props.existsInCart}>
          <div class="absolute top-2 right-2 w-8 h-8 border-4 border-white z-100">
            <input
              id={props.sku}
              data-sku={props.sku}
              type="checkbox"
              class="sr-only peer"
              onChange={(e) => {
                if (props.onCheck) props.onCheck(e.currentTarget.checked);
              }}
              checked={props.isChecked}
            />
            <label
              for={props.sku}
              class=" border w-full h-full bg-gray-200 hover:bg-white peer-checked:bg-roma-blue transition-colors cursor-pointer flex justify-center items-center"
            >
              <Icon
                path={check}
                class="text-gray-200 w-5 h-5"
                stroke-width={3}
              />
            </label>
          </div>
        </Show>
        <Show when={props.existsInCart}>
          <div class="absolute inset-0 backdrop-blur-[2px] bg-white/50">
            <div class="absolute top-2 inset-x-2 flex items-center justify-between">
              <span class="text-sm md:opacity-0 group-hover:opacity-100 text-gray-500 px-2 bg-white/80 rounded-sm select-none">
                Item In Cart
              </span>
              <Icon
                path={arrowDownTray}
                class="text-gray-300  w-7 h-7 cursor-not-allowed"
                stroke-width={2}
              />
            </div>
          </div>
        </Show>
      </div>
      <A
        href={`/product/${props.sku}`}
        target="_blank"
        class="border py-1 px-2 text-base md:text-xs font-light text-roma-medium-grey text-center cursor-pointer hover:bg-roma-grey relative"
      >
        <span class="inline-block">View Product</span>
        <Icon
          path={arrowTopRightOnSquare}
          class="inline-block w-3 h-3 ml-auto absolute right-1"
        />
      </A>
      <div>
        <div class="flex justify-between">
          <p class="font-bold">{props.sku}</p>
          <div class="flex gap-1 pr-1">
            <Switch>
              <Match when={props.is_new}>
                <div class="flex items-center text-xs font-medium text-roma-blue px-3 border border-roma-blue rounded-full group-hover:text-white group-hover:bg-roma-blue transition">
                  <p>New</p>
                </div>
              </Match>
              <Match when={props.is_discontinuing}>
                <div class="flex items-center text-xs font-medium px-3 border border-orange-300 bg-white rounded-full text-orange-300 group-hover:text-white group-hover:bg-orange-300 transition">
                  <p>Discontinuing</p>
                </div>
              </Match>
              <Match when={props.is_discontinued}>
                <div class="flex items-center text-xs font-medium px-3 border border-orange-300 bg-white rounded-full text-orange-300 group-hover:text-white group-hover:bg-orange-300 transition">
                  <p>Discontinued</p>
                </div>
              </Match>
            </Switch>
          </div>
        </div>
        <p class="text-roma-dark-grey text-base">
          {props.collection}, {props.colour}
        </p>
        <p class="text-roma-dark-grey fraction font-light text-sm">
          W{fractionString(props.width)} H{fractionString(props.height)}{" "}
          <Show when={props.rabbet > 0}>R{fractionString(props.rabbet)}</Show>
        </p>
      </div>
    </div>
  );
};
